import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import { ReactComponent as UpvoteIcon } from 'assets/button-upvote.svg'
import { ReactComponent as DownvoteIcon } from 'assets/button-downvote.svg'
import { EntityType } from 'util/constants'
import './Vote.css'

interface Props {
    entityId: number | string
    onVote?: () => void
    voteCount: number
    entityType: EntityType
}

type VoteType = 'user_upvote' | 'user_downvote' | 'user_unvote'

const Vote = ({
    entityId,
    onVote,
    voteCount,
    entityType,
}: Props) => {
    const { currentUser, setShowAuthModal } = useUser();
    const [upvotes, setUpvotes] = useState<number>(0)
    const [downvotes, setDownvotes] = useState<number>(0)
    const [entityVoteCount, setVoteCount] = useState<number>(voteCount)
    const [userVote, setUserVote] = useState<VoteType | null>(null)

    useEffect(() => {
        const fetchVotes = async () => {
            try {
                if (currentUser) {
                    const response = await api.get<VoteType>(
                        `/api/votes/user/${entityType}/${entityId}`
                    )
                    setUserVote(response.data)
                }
            } catch (error) {
                console.error('Error fetching votes:', error)
            }
        }

        fetchVotes()
    }, [entityId, entityType, currentUser])

    const handleVote = async (type: VoteType) => {
        if (!currentUser) {
            setShowAuthModal(true)
            return
        }

        try {
            let newVoteType: VoteType = type
            if (userVote === type) {
                newVoteType = 'user_unvote'
            }

            const voteData = {
                rel_type: newVoteType,
                user_id: currentUser.id,
            }

            await api.post(`/api/votes/${entityType}/${entityId}`, voteData)

            if (newVoteType === 'user_upvote') {
                setUpvotes(upvotes + 1)
                if (userVote === 'user_downvote') {
                    setDownvotes(downvotes - 1)
                    setVoteCount(entityVoteCount + 2)
                } else {
                    setVoteCount(entityVoteCount + 1)
                }
            } else if (newVoteType === 'user_downvote') {
                setDownvotes(downvotes + 1)
                if (userVote === 'user_upvote') {
                    setUpvotes(upvotes - 1)
                    setVoteCount(entityVoteCount - 2)
                } else {
                    setVoteCount(entityVoteCount - 1)
                }
            } else {
                if (userVote === 'user_upvote') {
                    setUpvotes(upvotes - 1)
                    setVoteCount(entityVoteCount - 1)
                } else if (userVote === 'user_downvote') {
                    setDownvotes(downvotes - 1)
                    setVoteCount(entityVoteCount + 1)
                }
            }

            setUserVote(newVoteType === 'user_unvote' ? null : type)
            if (onVote) onVote()
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                setShowAuthModal(true)
            } else {
                console.error('Error voting:', error)
            }
        }
    }

    return (
        <div className={`${entityType !== 'comment' ? 'vote-container rounded-full bg-gray-200 flex flex-row items-center mx-2 md:flex-col md:my-2' : 'vote-container rounded-full flex flex-row items-center mr-2'}`}>
            <div className={`${entityType !== 'comment' ? 'size-9 rounded-full bg-gray-200 flex justify-center items-center hover:bg-gray-300 transition-colors' : 'size-9 rounded-full flex justify-center items-center hover:bg-gray-300 transition-colors'}`}>
                <button
                    onClick={() => handleVote('user_upvote')}
                    className={userVote === 'user_upvote' ? 'active' : ''}
                >
                    <UpvoteIcon />
                </button>
            </div>
            <div className={`vote-count px-1 ${entityType !== 'comment' ? '' : 'mb-0.5'}`}>
                <span className="text-xs font-semibold text-eoe-dark-gray">{entityVoteCount}</span>
            </div>
            <div className={`${entityType !== 'comment' ? 'size-9 rounded-full bg-gray-200 flex justify-center items-center hover:bg-gray-300 transition-colors' : 'size-9 rounded-full flex justify-center items-center hover:bg-gray-300 transition-colors'}`}>
                <button
                    onClick={() => handleVote('user_downvote')}
                    className={
                        userVote === 'user_downvote'
                            ? 'active downvote'
                            : 'downvote'
                    }
                >
                    <DownvoteIcon />
                </button>
            </div>
        </div>
    )
}

export default Vote
