import React, { useState } from 'react';
import { useUser } from 'util/UserContext';
import {
  Modal,
  ModalDialog,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  FormHelperText,
  Stack,
} from '@mui/joy';
import TextEditor from 'components/TextEditor';
import api from 'config/axiosConfig';
import { Feedback } from 'util/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  onFeedbackAdded: (feedback: Feedback) => void;
}

const AddFeedback = ({ open, onClose, onFeedbackAdded }: Props) => {
  const { currentUser, setShowAuthModal } = useUser();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [category, setCategory] = useState<Feedback['properties']['category']>('Bug');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleAddFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) {
      setShowAuthModal(true);
      return;
    }

    if (!title || !description) {
      setError('Title and description are required.');
      return;
    }

    try {
      const feedbackData = {
        title,
        description,
        category,
        user_id: currentUser.id,
      };

      const response = await api.post<Feedback>('/api/feedback', feedbackData);

      const newFeedback = response.data;

      setSuccess('Feedback added successfully!');
      setError('');
      onFeedbackAdded(newFeedback);
      setTitle('');
      setDescription('');
      setCategory('Bug');
      onClose();
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setShowAuthModal(true);
      } else {
        setError('Failed to add feedback. Please try again.');
        setSuccess('');
      }
    }
  };

  const handleCategorySelect = (
    _event: React.SyntheticEvent | null,
    newValue: Feedback['properties']['category'] | null
  ) => {
    if (newValue) setCategory(newValue);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <form onSubmit={handleAddFeedback}>
          <FormControl required>
            <FormLabel>Title</FormLabel>
            <Input
              type="text"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl required>
            <FormLabel>Category</FormLabel>
            <Select value={category} onChange={handleCategorySelect}>
                <Option value="Bug">Bug</Option>
                <Option value="Enhancement">Enhancement</Option>
                <Option value="Functionality">Functionality</Option>
                <Option value="User Interface (UI)">User Interface (UI)</Option>
                <Option value="User Experience (UX)">User Experience (UX)</Option>
                <Option value="Performance">Performance</Option>
                <Option value="Compliance">Compliance</Option>
                <Option value="Other">Other</Option>
            </Select>
          </FormControl>
          <FormControl required>
            <FormLabel>Description</FormLabel>
            <TextEditor
              value={description}
              onChange={setDescription}
              editorType="entity"
            />
          </FormControl>
          {error && <FormHelperText color="danger">{error}</FormHelperText>}
          {success && <FormHelperText color="success">{success}</FormHelperText>}
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="plain" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Add Feedback</Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default AddFeedback;