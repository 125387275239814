import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/joy';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface ScrollButtonProps {
  direction: 'left' | 'right';
  onClick: () => void;
  visible: boolean;
}

const StyledIconButton = styled(IconButton)<{ $visible: boolean; $direction: 'left' | 'right' }>`
  border-radius: 50%;
  position: absolute;
  border-width: 0px;
  z-index: 2;
  background-color: white;
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  ${({ $direction }) => $direction === 'left' ? 'left: 0;' : 'right: 0;'}
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    background-color: #e5e7eb;
  }
`;

const ScrollButton: React.FC<ScrollButtonProps> = ({ direction, onClick, visible }) => {
  return (
    <StyledIconButton
      onClick={onClick}
      $visible={visible}
      $direction={direction}
      aria-label={`Scroll ${direction}`}
    >
      {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </StyledIconButton>
  );
};

export default ScrollButton;