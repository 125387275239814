import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import {
    Modal,
    ModalDialog,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormHelperText,
    Typography,
    Link as JoyLink,
    Autocomplete,
} from '@mui/joy'
import api from 'config/axiosConfig'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Enemy } from 'util/constants'

interface Props {
    open: boolean
    onClose: (value: boolean) => void
}

interface WikiSearchResult {
    title: string
    link: string
}

const AddEnemy = ({ open, onClose }: Props) => {
    const { currentUser, setShowAuthModal } = useUser();
    const [wikipediaLink, setWikipediaLink] = useState('')
    const [name, setName] = useState('')
    const [error, setError] = useState('')
    const [submitEnabled, setSubmitEnabled] = useState(false)
    const [existingEnemyLink, setExistingEnemyLink] = useState('')
    const [searchResults, setSearchResults] = useState<WikiSearchResult[]>([])
    const [isChecking, setIsChecking] = useState(false);

    const validateWikipediaLink = (link: string) => {
        const regex = /^https:\/\/en\.wikipedia\.org\/wiki\/[a-zA-Z0-9_().,-]+$/
        if (regex.test(link)) {
            const namePart = decodeURIComponent(
                link.split('/wiki/')[1]
            ).replace(/_/g, ' ')
            setName(namePart)
            setError('')
            setIsChecking(true)
            setSubmitEnabled(true)
            checkEnemyExistence(namePart)
        } else {
            setError(
                'Invalid Wikipedia link. Example: https://en.wikipedia.org/wiki/Crime'
            )
            setSubmitEnabled(false)
            setIsChecking(false)
        }
    }

    const handleWikipediaLinkChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value
        setWikipediaLink(value)
        validateWikipediaLink(value)
    }

    const handleSearchChange = async (
        event: React.SyntheticEvent<Element, Event>,
        value: string,
        reason: string
    ) => {
        if (value.length > 2) {
            try {
                const response = await fetch(`https://en.wikipedia.org/w/api.php?action=opensearch&search=${encodeURIComponent(value)}&limit=5&namespace=0&format=json&origin=*`)
                const data = await response.json()
                const results: WikiSearchResult[] = data[1].map((title: string, index: number) => ({
                    title,
                    link: data[3][index]
                }))
                setSearchResults(results)
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
        } else {
            setSearchResults([])
        }
    }

    const checkEnemyExistence = async (enemyName: string) => {
        setIsChecking(true)
        setSubmitEnabled(false)
        try {
            const response = await api.get(
                `/api/enemies?search=${encodeURIComponent(enemyName)}`
            )
            if (response.data.length > 0) {
                const existingEnemy = response.data.find(
                    (enemy: Enemy) =>
                        enemy.name.toLowerCase() === enemyName.toLowerCase()
                )
                if (existingEnemy) {
                    const enemyUrl = `/enemy/${encodeURIComponent(existingEnemy.name.replace(/ /g, '_'))}`
                    setExistingEnemyLink(enemyUrl)
                    setError(`The enemy already exists: `)
                    setSubmitEnabled(false)
                } else {
                    setExistingEnemyLink('')
                    setSubmitEnabled(true)
                }
            } else {
                setExistingEnemyLink('')
                setSubmitEnabled(true)
            }
        } catch (err) {
            console.error('Failed to check if enemy exists:', err)
            setError('Failed to check if enemy exists. Please try again.')
            setSubmitEnabled(false)
        } finally {
            setIsChecking(false)
        }
    }
    
    // Update handleSearchSelect to use the new function
    const handleSearchSelect = async (
        event: React.SyntheticEvent<Element, Event>,
        value: string | WikiSearchResult | null,
        reason: string
    ) => {
        if (value && typeof value !== 'string') {
            try {
                const response = await fetch(`https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(value.title)}`)
                const data = await response.json()
                const wikipediaLink = data.content_urls.desktop.page
                setWikipediaLink(wikipediaLink)
                validateWikipediaLink(wikipediaLink) // Add this line
            } catch (error) {
                console.error('Error fetching search results:', error)
            }
        } 
    }
    
    // Update the useEffect hook to use the new function
    useEffect(() => {
        const checkIfEnemyExists = async () => {
            if (name) {
                await checkEnemyExistence(name)
            }
        }
    
        checkIfEnemyExists()
    }, [name])

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        if (!currentUser) {
            setShowAuthModal(true)
            return
        }

        try {
            const standardizedLink = wikipediaLink.replace(
                /^https?:\/\/(www\.)?wikipedia\.org\/wiki\//,
                'https://en.wikipedia.org/wiki/'
            )
            const response = await api.post('/api/enemies', {
                wikipedia_link: standardizedLink,
                user_id: currentUser.id,
            })
            if (response.status === 201) {
                window.location.href = `/enemy/${encodeURIComponent(name.replace(/ /g, '_'))}?upvoted=true`
            }
        } catch (error) {
            console.error('Failed to create enemy:', error)
            setError('Failed to create enemy. Please try again later.')
        }
    }

    return (
        <>
            <Modal
                component="div"
                open={open}
                onClose={onClose}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ModalDialog>
                    <Typography level="h4">Add an Enemy</Typography>
                    <Typography sx={{ fontSize: '0.875rem' }}>
                        Search for an enemy by name, or provide a Wikipedia link.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                mb: 2,
                                flexDirection: 'column',
                            }}
                        >
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Autocomplete
                                    freeSolo
                                    options={searchResults}
                                    getOptionLabel={(
                                        option: string | WikiSearchResult
                                    ) =>
                                        typeof option === 'string'
                                            ? option
                                            : option.title
                                    }
                                    onInputChange={handleSearchChange}
                                    onChange={handleSearchSelect}
                                />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>Wikipedia Link</FormLabel>
                                <Input
                                    type="text"
                                    value={wikipediaLink}
                                    onChange={handleWikipediaLinkChange}
                                    placeholder="https://en.wikipedia.org/wiki/Crime"
                                />
                            </FormControl>
                        </Box>
                        {error && (
                            <FormHelperText color="danger">
                                {existingEnemyLink ? (
                                    <>
                                        <InfoOutlined color="error" />
                                        <JoyLink
                                            href={existingEnemyLink}
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            {error}
                                            {name}
                                        </JoyLink>
                                    </>
                                ) : (
                                    <span>{error}</span>
                                )}
                            </FormHelperText>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                justifyContent: 'flex-end',
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="plain"
                                color="neutral"
                                onClick={() => onClose(false)}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" disabled={!submitEnabled || isChecking}>
                            {isChecking ? 'Checking...' : 'Submit'}
                            </Button>
                        </Box>
                    </form>
                </ModalDialog>
            </Modal>
        </>
    )
}

export default AddEnemy