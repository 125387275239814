import React, { useEffect, useState } from 'react';
import api from 'config/axiosConfig';
import EnemyCard from 'components/Enemies/EnemyCard';
import { CircularProgress } from '@mui/joy';
import { Enemy } from 'util/constants';

interface Props {
  enemyId: number | null;
}

const ImpactEnemy = ({ enemyId }: Props) => {
  const [enemy, setEnemy] = useState<Enemy | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchEnemy = async () => {
      if (!enemyId) return;
      try {
        const response = await api.get<Enemy>(`/api/enemies/id/${enemyId}`);
        setEnemy(response.data);
      } catch (err) {
        setError('Failed to load related enemy. Please try again later.');
      }
    };

    fetchEnemy();
  }, [enemyId]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!enemy) {
    return (
      <div>
        <CircularProgress size="lg" />
      </div>
    );
  }

  return (
    <div className="related-enemy-container entity-divider">
      <EnemyCard
        enemy={enemy}
        showMeta={true}
        showActions={true}
        onVote={() => {}}
        truncateDescription={true}
      />
    </div>
  );
};

export default ImpactEnemy;
