import { extendTheme } from '@mui/joy/styles';
import { tabClasses } from '@mui/joy';

const customTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
            50: '#f9fafb',
            100: '#f3f4f6',
            200: '#e5e7eb',
            300: '#d1d5db',
            400: '#9ca3af',
            500: '#6b7280',
            600: '#4b5563',
            700: '#374151',
            800: '#1f2937',
            900: '#555555',
        },  
        neutral: {
            50: '#f9fafb',
            100: '#f3f4f6',
            200: '#d1d5db',
            300: '#e5e7eb',
            400: '#9ca3af',
            500: '#6b7280',
            600: '#4b5563',
            700: '#374151',
            800: '#1f2937',
            900: '#555555',
        },
      },
    },
    dark: {
        palette: {
          primary: {
              50: '#f9fafb',
              100: '#f3f4f6',
              200: '#e5e7eb',
              300: '#d1d5db',
              400: '#9ca3af',
              500: '#6b7280',
              600: '#4b5563',
              700: '#374151',
              800: '#1f2937',
              900: '#555555',
          },  
          neutral: {
            50: '#f9fafb',
            100: '#f3f4f6',
            200: '#d1d5db',
            300: '#e5e7eb',
            400: '#9ca3af',
            500: '#6b7280',
            600: '#4b5563',
            700: '#374151',
            800: '#1f2937',
            900: '#555555',
          },
        },
    },
  },
  components: {
    JoyTabs: {
      styleOverrides: {
        root: {
          // Styles applied to the root element of Tabs
          '--Tabs-width': '100%',
          '--Tabs-gap': '4px',
          '--justifyContent': 'space-between',
          '--Tabs-radius': '20px', // Adjust the border radius // Tailwind's border-gray-200
          paddingTop: '10px',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          borderWidth: '0px',
        },
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: {
          borderWidth: '0px',
          border: 'none',
          order: 'last',
          padding: '0px',
          gap: '8px',
          overflow: 'auto',
          scrollSnapType: 'x mandatory',
          backgroundColor: 'white',
          '&::-webkit-scrollbar': { display: 'none' },
          '&::-webkit-scrollbar-thumb': { display: 'none' },
        },
      },
    },
    JoyTab: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontWeight: theme.vars.fontWeight.lg,
            borderRadius: '30px',
            borderWidth: '0px',
            borderBottom: '0px',
            textTransform: 'none',
            padding: '0.5rem',
            minHeight: 'auto',
            minWidth: 'auto',
            color: 'primary',
            backgroundColor: theme.vars.palette.primary[100],
            transition: 'background-color 0.3s', // Add smooth transition
            '&:hover': {
              backgroundColor: theme.vars.palette.primary[300],
            },
            [`&.#${tabClasses.selected}`]: {
              color: 'primary',
              backgroundColor: theme.vars.palette.primary[300],
              '&:hover': {
                backgroundColor: theme.vars.palette.primary[300]
              },
            },
          }),
      },
    },
    JoyTabPanel: {
      styleOverrides: {
        root: {
          // Styles applied to each TabPanel
          backgroundColor: 'white',
          borderWidth: '0px',
          padding: '1rem 0',
        },
      },
    },
  },
});

export default customTheme;
