// SolutionProfile.tsx

import { useEffect, useState } from 'react';
import api from 'config/axiosConfig';
import { useParams, useSearchParams } from 'react-router-dom';
import SolutionCard from 'components/Solutions/SolutionCard';
import { Container, CircularProgress } from '@mui/joy';
import SolutionProfileTabs from './SolutionProfileTabs';
import SolutionEditor from './SolutionEditor';
import { Solution } from 'util/constants';
import { setDocumentTitle } from 'util/pageTitles';

const SolutionProfile = () => {
    const { id } = useParams<{ id: string }>()
    const [solution, setSolution] = useState<Solution | null>(null)
    const [error, setError] = useState<string>('')
    const [editMode, setEditMode] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [referenceLink, setReferenceLink] = useState<string>('')
    const [searchParams] = useSearchParams()

  useEffect(() => {
      setDocumentTitle(`Solution - ${solution?.name}`);
  }, [solution?.name]);

  useEffect(() => {
    const fetchSolution = async () => {
        try {
            const response = await api.get<Solution>(
                `/api/solutions/id/${id}`
            )
            setSolution(response.data)
            setDescription(response.data.description)
            setReferenceLink(response.data.reference_link)
            if (searchParams.get('edit') === 'true') {
                setEditMode(true)
            }
        } catch (err) {
            setError(
                'Failed to load solution profile. Please try again later.'
            )
        }
    }

    fetchSolution();
  }, [id, searchParams]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!solution) {
    return (
      <div>
        <CircularProgress size="lg" />
      </div>
    );
  }

  return (
    <div className='component-divider grid grid-rows-auto grid-flow-row gap-2'>
      <Container>
            {solution && (
                <>
                    {editMode ? (
                        <SolutionEditor
                            solution={solution}
                            description={description}
                            referenceLink={referenceLink}
                            editMode={true}
                        />
                    ) : (
                        <SolutionCard
                            solution={solution}
                            isSolutionProfilePage={true}
                            truncateDescription={true}
                        />
                    )}
                </>
            )}
      </Container>
      <SolutionProfileTabs solution={solution} />
    </div>
  );
};

export default SolutionProfile;
