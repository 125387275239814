import { Select, Option } from '@mui/joy'
import { Sort } from 'util/constants'

interface Props {
    entityType: string
    sort: Sort
    onSortChange: (newSort: Sort) => void
}

const EntitiesSort = ({ sort, onSortChange }: Props) => {

    const handleSortChange = (newSort: Sort) => {
        onSortChange(newSort)
    }

    return (
        <Select size="sm" value={sort} onChange={(_, newValue) => handleSortChange(newValue as Sort)}>
            <Option value="top">Top</Option>
            <Option value="new">New</Option>
            <Option value="bottom">Bottom</Option>
            <Option value="old">Old</Option>
        </Select>
    )
}

export default EntitiesSort