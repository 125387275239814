import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    FormLabel,
    FormControl,
    Select,
    Option,
    CircularProgress,
    Input,
} from '@mui/joy'
import TextEditor from 'components/TextEditor';
import { Feedback } from 'util/constants'

interface Props {
    feedback: Feedback
    title: Feedback[`title`]
    description: Feedback[`description`]
    category: Feedback[`properties`][`category`]
    status: Feedback[`status`]
    editMode?: boolean
}

const FeedbackEditor = ({
    feedback,
    title,
    description,
    category,
    status,
    editMode = true,
}: Props) => {
    const { currentUser, setShowAuthModal } = useUser()
    const navigate = useNavigate()
    const [newTitle, setTitle] = useState(title)
    const [newDescription, setDescription] = useState(description)
    const [newCategory, setCategory] = useState(category)
    const [newStatus, setStatus] = useState(status)
    const [authorized, setAuthorized] = useState<boolean>(true)

    useEffect(() => {
        if (!currentUser) {
            setAuthorized(false)
            setShowAuthModal(true)
        } else if (currentUser.id !== feedback.user_id && !currentUser.attributes.is_admin) {
            setAuthorized(false)
            navigate(`/feedback/${feedback.id}`)
            window.location.reload()
        }
    }, [currentUser, setShowAuthModal, feedback, navigate])

    if (!authorized) {
        return null // Prevent rendering if not authorized
    }

    const handleCategoryChange = (
        _event: React.SyntheticEvent | null,
        newValue: Feedback['properties']['category']
    ) => {
        setCategory(newValue)
    }

    const handleStatusChange = (
        _event: React.SyntheticEvent | null,
        newValue: Feedback['status'] | null
    ) => {
        setStatus(newValue || '')
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        try {
            await api.patch(`/api/feedback/${feedback.id}`, {
                title: newTitle,
                description: newDescription,
                category: newCategory,
                status: newStatus,
            })
            navigate(`/feedback/${feedback.id}`)
            window.location.reload()
        } catch (error) {
            console.error('Error saving feedback:', error)
        }
    }

    const handleCancel = () => {
        navigate(`/feedback/${feedback.id}`)
        window.location.reload()
    }

    if (!feedback) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="card-margin">
            <div>
                {feedback && (
                    <div className="edit-feedback-card">
                        <FormControl>
                            <FormLabel>Title</FormLabel>
                            <Input
                                value={newTitle}
                                onChange={handleTitleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Category</FormLabel>
                            <Select
                                value={newCategory}
                                onChange={handleCategoryChange}
                            >
                                <Option value="Bug">Bug</Option>
                                <Option value="Enhancement">Enhancement</Option>
                                <Option value="Functionality">Functionality</Option>
                                <Option value="User Interface (UI)">User Interface (UI)</Option>
                                <Option value="User Experience (UX)">User Experience (UX)</Option>
                                <Option value="Performance">Performance</Option>
                                <Option value="Compliance">Compliance</Option>
                                <Option value="Other">Other</Option>
                            </Select>
                        </FormControl>
                        {currentUser?.attributes.is_admin && (
                            <FormControl>
                                <FormLabel>Status</FormLabel>
                                <Select
                                    value={newStatus}
                                    onChange={handleStatusChange}
                                >
                                    <Option value="Open">Open</Option>
                                    <Option value="Reviewing">Reviewing</Option>
                                    <Option value="Planned">Planned</Option>
                                    <Option value="Unplanned">Unplanned</Option>
                                    <Option value="Developing">Developing</Option>
                                    <Option value="Released">Released</Option>
                                    <Option value="Closed">Closed</Option>
                                </Select>
                            </FormControl>
                        )}
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <TextEditor
                            value={description}
                            onChange={setDescription}
                            editorType="entity"
                            />
                        </FormControl>
                        <div className="edit-actions">
                            <Button
                                variant="outlined"
                                color="neutral"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="solid"
                                color="primary"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FeedbackEditor