import React, { useState, useEffect, useCallback, useRef } from 'react'
import api from 'config/axiosConfig'
import EnemyCard from './EnemyCard'
import CircularProgress from '@mui/material/CircularProgress'

import { Select, Option, Box, Typography } from '@mui/joy'
import {
    Enemy,
    Sort,
    SortField,
    SortOrder,
    Status,
    EnemyType,
    Entity,
} from 'util/constants'

interface Props {
    sort: Sort
}

const EnemiesList = ({ sort }: Props) => {
    const sortOptions = [
        { label: 'Top', value: 'top', link: '/top/' },
        { label: 'New', value: 'new', link: '/new/' },
        { label: 'Bottom', value: 'bottom', link: '/bottom/' },
        { label: 'Old', value: 'old', link: '/old/' },
    ]

    const [enemies, setEnemies] = useState<Enemy[]>([])
    const [error, setError] = useState('')
    const [filterType, setFilterType] = useState<EnemyType>('All')
    const [filterStatus, setFilterStatus] = useState<Status>('All')
    const [sortField, setSortField] = useState<SortField>()
    const [sortOrder, setSortOrder] = useState<SortOrder>()
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1) // For pagination
    const [hasMore, setHasMore] = useState(true)

    // Determine sortField and sortOrder based on the "sort" parameter
    useEffect(() => {
        switch (sort) {
            case 'top':
                setSortField('vote_count')
                setSortOrder('desc')
                break
            case 'bottom':
                setSortField('vote_count')
                setSortOrder('asc')
                break
            case 'new':
                setSortField('created_ts')
                setSortOrder('desc')
                break
            case 'old':
                setSortField('created_ts')
                setSortOrder('asc')
                break
            default:
                setSortField('vote_count')
                setSortOrder('desc')
                break
        }
    }, [sort])

    // Fetch enemies only when sortField and sortOrder are set
    useEffect(() => {
        if (sortField && sortOrder) {
            setLoading(true)
            setEnemies([]) // Clear the current list when sorting changes
            setPage(1) // Reset to first page

            fetchEnemies(1) // Fetch the first page with new sorting
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortField, sortOrder])

    const fetchEnemies = async (page: number) => {
        try {
            const response = await api.get('/api/entities', {
                params: {
                    entityType: 'enemy',
                    sortField: sortField,
                    sortOrder: sortOrder,
                    limit: 10,
                    offset: (page - 1) * 10, // Pagination logic
                },
            })

            if (response.data.length < 10) {
                setHasMore(false) // If fewer than 10 entities are fetched, there are no more pages
            }

            setEnemies((prevEnemies: Enemy[]) => {
                const enem = response.data.map(
                    (enemy: Entity) => enemy.attributes
                )
                const newEnemies = enem.filter(
                    (newEnemy: Enemy) =>
                        !prevEnemies.some((enemy) => enemy.id === newEnemy.id)
                )
                return [...prevEnemies, ...newEnemies]
            })
            setLoading(false)
        } catch (error) {
            setError('Failed to fetch enemies')
            setLoading(false)
        }
    }

    // Fetch more enemies as the user scrolls down
    useEffect(() => {
        if (!loading) {
            fetchEnemies(page)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const observer = useRef<IntersectionObserver | null>(null);
    const lastEnemyElementRef = useCallback((node: HTMLDivElement | null) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1  // Adjust this value to control when new content loads
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const handleTypeChange = (
        event:
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
            | React.FocusEvent<Element, Element>
            | null,
        newValue: EnemyType
    ) => setFilterType(newValue)
    const handleStatusChange = (
        event:
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
            | React.FocusEvent<Element, Element>
            | null,
        newValue: Status
    ) => setFilterStatus(newValue)

    const handleSortChange = (
        event:
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
            | React.FocusEvent<Element, Element>
            | null,
        newValue: Sort | null
    ) => {
        const selectedOption = sortOptions.find(
            (option) => option.value === newValue
        )
        if (selectedOption) {
            window.location.href = selectedOption.link // Update the URL to match the selected sort option
        }
    }

    const filteredEnemies = enemies.filter((enemy) => {
        return (
            (filterType === 'All' || enemy.properties?.type === filterType) &&
            (filterStatus === 'All' || enemy.properties?.status === filterStatus)
        )
    })

    if (loading && page === 1) {
        return <CircularProgress />
    }

    if (error) {
        return <div>{error}</div>
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    py: 1,
                    px: 2,
                    borderBottom: '0.05rem solid #c8c8c8',
                }}
            >
                <Select
                    size="sm"
                    value={sort || 'top'}
                    onChange={handleSortChange}
                >
                    {sortOptions.map((sortButton) => (
                        <Option key={sortButton.value} value={sortButton.value}>
                            <Box sx={{ display: 'flex', alignItems: 'left' }}>
                                <Typography textAlign="left" sx={{ ml: 1 }}>
                                    {sortButton.label}
                                </Typography>
                            </Box>
                        </Option>
                    ))}
                </Select>

                <Select
                    size="sm"
                    value={filterType}
                    onChange={handleTypeChange}
                >
                    <Option value="All">Type</Option>
                    <Option value="Person">Person</Option>
                    <Option value="Corporation">Corporation</Option>
                    <Option value="Organization">Organization</Option>
                    <Option value="Regulation">Regulation</Option>
                    <Option value="Concept">Concept</Option>
                    <Option value="Environment">Environment</Option>
                    <Option value="Territory">Territory</Option>
                    <Option value="Other">Other</Option>
                </Select>

                <Select
                    size="sm"
                    value={filterStatus}
                    onChange={handleStatusChange}
                >
                    <Option value="All">Status</Option>
                    <Option value="Active">Active</Option>
                    <Option value="Eliminated">Eliminated</Option>
                </Select>
            </Box>
            <div className="flex flex-col gap-2 divide-y divide-gray-200">
                {filteredEnemies.map((enemy, index) => (
                    <div
                        key={enemy.id}
                        ref={index === filteredEnemies.length - 1 ? lastEnemyElementRef : null}
                    >
                        <EnemyCard
                            key={enemy.id}
                            enemy={enemy}
                            showMeta={true}
                            showActions={true}
                            onVote={() => {}}
                            truncateDescription={true}
                        />
                    </div>
                ))}
            </div>
            {loading && <CircularProgress />}
        </div>
    )
}

export default EnemiesList
