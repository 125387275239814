import React, { useState } from 'react'
import { useUser } from 'util/UserContext'
import {
    Modal,
    ModalDialog,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Option,
    FormHelperText,
    Stack,
} from '@mui/joy'
import TextEditor from 'components/TextEditor';
import api from 'config/axiosConfig'
import { Impact, Scope } from 'util/constants'

interface Props {
    enemyId: number
    onImpactAdded: (impact: Impact) => void
    openModal: () => void
    onClose: () => void
}

const AddImpact = ({
    enemyId,
    onImpactAdded,
    openModal,
    onClose,
}: Props) => {
    const { currentUser, setShowAuthModal } = useUser();
    const [referenceLink, setReferenceLink] = useState<string>('')
    const [scope, setScope] = useState<Scope>(null)
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [impactType, setImpactType] = useState<
        'impact_negative' | 'impact_positive'
    >('impact_negative')
    const [error, setError] = useState<string>('')
    const [success, setSuccess] = useState<string>('')

    const handleAddImpact = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!currentUser) {
            setShowAuthModal(true)
            return
        }

        try {
            let standardizedLink = referenceLink.trim()

            // Check if the link starts with "https://", otherwise replace it
            if (!standardizedLink.startsWith('https://')) {
                standardizedLink = standardizedLink.replace(
                    /^(.*?:\/\/)?/,
                    'https://'
                )
            }

            const impactData = {
                reference_link: standardizedLink,
                scope,
                description,
                title,
                rel_type: impactType,
                user_id: currentUser.id,
            }

            const response = await api.post<Impact>(
                `/api/impacts/enemies/${enemyId}`,
                impactData
            )

            const newImpact = response.data

            // Automatically upvote the impact
            await api.post(`/api/votes/impact/${newImpact.impact_id}`, {
                user_id: currentUser.id,
                rel_type: 'user_upvote',
            })

            setSuccess('Impact added successfully!')
            setError('')
            onImpactAdded(newImpact)
            setReferenceLink('')
            setScope(null)
            setDescription('')
            onClose()
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                setShowAuthModal(true)
            } else {
                setError('Failed to add impact. Please try again.')
                setSuccess('')
            }
        }
    }

    const handleScopeSelect = (
        _event: React.SyntheticEvent | null,
        newValue: Scope | null
    ) => {
        if (newValue) setScope(newValue)
    }

    const handleImpactTypeSelect = (
        _event: React.SyntheticEvent | null,
        newValue: 'impact_negative' | 'impact_positive' | null
    ) => {
        if (newValue) setImpactType(newValue)
    }

    return (
        <Modal open={true} onClose={openModal}>
            <ModalDialog>
                <form onSubmit={handleAddImpact}>
                    <FormControl required>
                        <FormLabel>Title</FormLabel>
                        <Input
                            type="text"
                            value={title}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setTitle(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Reference Link</FormLabel>
                        <Input
                            type="url"
                            value={referenceLink}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setReferenceLink(e.target.value)}
                        />
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Scope</FormLabel>
                        <Select value={scope} onChange={handleScopeSelect}>
                            <Option value="">Select Scope</Option>
                            <Option value="Local">Local</Option>
                            <Option value="National">National</Option>
                            <Option value="Global">Global</Option>
                        </Select>
                    </FormControl>
                    <FormControl required>
                        <FormLabel>Impact Type</FormLabel>
                        <Select
                            value={impactType}
                            onChange={handleImpactTypeSelect}
                        >
                            <Option value="impact_negative">Negative</Option>
                            <Option value="impact_positive">Positive</Option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Description</FormLabel>
                            <TextEditor
                            value={description}
                            onChange={setDescription}
                            editorType="entity"
                            />
                    </FormControl>
                    {error && (
                        <FormHelperText color="danger">{error}</FormHelperText>
                    )}
                    {success && (
                        <FormHelperText color="success">
                            {success}
                        </FormHelperText>
                    )}
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="plain" onClick={openModal}>
                            Cancel
                        </Button>
                        <Button type="submit">Add Impact</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}

export default AddImpact
