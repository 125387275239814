import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CommentsSection from 'components/Comments/CommentsSection';
import { Box, Tabs, Tab, TabPanel, Container } from '@mui/joy';
import ScrollableTabList from 'ui/ScrollableTabList'
import { Feedback } from 'util/constants';

interface Props {
  feedback: Feedback;
}

const FeedbackProfileTabs = ({ feedback }: Props) => {
  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const [selectedTab, setSelectedTab] = useState(tab || 'comments');

  return (
    <Box>
      <Container>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(`${newValue}`);
          }}
          aria-label="Feedback Profile Tabs"
        >
          <ScrollableTabList
            sticky="top"
            disableUnderline={true}
          >
            <Tab
              component={Link}
              to={`/feedback/${id}/comments`}
              value="comments"
              disableIndicator={true}
            >
              Comments
            </Tab>
          </ScrollableTabList>
          <TabPanel value="comments">
            <CommentsSection
              commentable_id={feedback.id}
              commentable_type="feedback"
            />
          </TabPanel>
        </Tabs>
      </Container>
    </Box>
  );
};

export default FeedbackProfileTabs;