import React from 'react'
import { useUser } from 'util/UserContext'
import Drawer from '@mui/material/Drawer'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import ListItemContent from '@mui/joy/ListItemContent'
import ListItemButton from '@mui/joy/ListItemButton'
import ListSubheader from '@mui/material/ListSubheader'
import ListDivider from '@mui/joy/ListDivider'
import Typography from '@mui/joy/Typography'
import Chip from '@mui/joy/Chip'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import ExploreIcon from '@mui/icons-material/Explore'
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const CustomDrawer = styled(Drawer)(({ theme }) => ({
    top: theme.mixins.toolbar.minHeight,
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    '& .MuiDrawer-paper': {
      top: theme.mixins.toolbar.minHeight,
      height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
      width: '240px',
      zIndex: theme.zIndex.drawer,
      borderTop: '0.1rem solid #c8c8c8',
    },
}));


const SideNavBar = ({ isOpen, toggleDrawer, onClick }: { isOpen: boolean, toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void, onClick: () => void }) => {
    const { currentUser } = useUser()
    return (
        <div>
        <CustomDrawer
            variant="permanent"
            className="hidden xl:block"
        >
                <Chip
                component={'span'}
                color="neutral"
                variant="soft"
                className='bg-eoe-blue'
                size="lg"
                sx={{
                    backgroundColor: '#005286',
                    color: '#fff',
                    fontSize: '0.73rem',
                    top: '0.35rem',
                    marginY: '0.5rem',
                    marginX: '0.2rem',
                }}
                >
                    United we stand,
                    prioritized they fall
                </Chip>
                <List size="lg" variant="plain" component="nav" className='side-nav-bar-list font-light' onClick={onClick}
                    sx={{
                        "--ListDivider-gap": "6px",
                        "--ListItemDecorator-size": "32px",
                        "--ListItemDecorator-fontSize": "40px",
                        "--ListItemContent-gap": "10px",
                        "--ListSubheader-fontSize": "16px",
                        "--ListItemContent-fontWeight": "light",
                        "--ListItemButton-paddingLeft": "16px",
                        "--List-border": "none",
                    }}>
                    <ListItem sx={{
                        paddingTop: '0',
                    }}>
                    <ListItemButton component={Link} to="/">
                        <ListItemDecorator>
                            <HomeOutlinedIcon sx={{ fontSize: '24px'}}/>
                        </ListItemDecorator>
                        <ListItemContent>Home</ListItemContent>
                    </ListItemButton>
                    </ListItem>
                    <ListItem>
                    <ListItemButton disabled component={Link} to="/explore">
                        <ListItemDecorator>
                            <ExploreIcon sx={{ fontSize: '24px'}}/>
                        </ListItemDecorator>
                        <ListItemContent>Explore</ListItemContent>
                    </ListItemButton>
                    </ListItem>
                    <ListItem>
                    <ListItemButton component={Link} to="/feedback">
                        <ListItemDecorator>
                            <ReviewsOutlinedIcon sx={{ fontSize: '24px'}}/>
                        </ListItemDecorator>
                        <ListItemContent>Feedback</ListItemContent>
                    </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    {currentUser && currentUser.attributes.is_admin && (
                    <ListItem nested>
                        <ListSubheader sx={{ fontSize: '16px', paddingLeft: '4px', fontWeight: 'semi-bold' }}>Admin</ListSubheader>
                            <ListItem>
                            <ListItemButton component={Link} to="/admin">
                                <ListItemDecorator>
                                    <AdminPanelSettingsOutlinedIcon sx={{ fontSize: '24px'}}/>
                                </ListItemDecorator>
                                <ListItemContent>Admin Home</ListItemContent>
                            </ListItemButton>
                            </ListItem>                            
                            <ListItem>
                            <ListItemButton component={Link} to="/admin/reports">
                                <ListItemDecorator>
                                    <FlagCircleOutlinedIcon sx={{ fontSize: '24px'}}/>
                                </ListItemDecorator>
                                <ListItemContent>Reported Items</ListItemContent>
                            </ListItemButton>
                            </ListItem>
                            <ListItem>
                            <ListItemButton component={Link} to="/admin/feedback">
                                <ListItemDecorator>
                                    <BugReportOutlinedIcon sx={{ fontSize: '24px'}}/>
                                </ListItemDecorator>
                                <ListItemContent>User Feedback</ListItemContent>
                            </ListItemButton>
                            </ListItem>
                    </ListItem>
                    )}
                </List>

                {/* Footer */}
                <Typography
                component="span"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    px: 2,
                    py: 2,
                    fontSize: '10px', // Override text size here
                    fontWeight: 'light',
                    color: 'text.secondary',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                }}
                >
                <a
                    className="societea-footer"
                    href="https://societea.io"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    Societea, Inc. © 2024. All rights reserved.
                </a>
                </Typography>
             </CustomDrawer>

             
             <CustomDrawer
                anchor="left"
                open={isOpen}
                onClose={toggleDrawer(false)}
                className="block xl:hidden"
            >
                <Chip
                component={'span'}
                color="neutral"
                variant="soft"
                className='bg-eoe-blue'
                size="lg"
                sx={{
                    backgroundColor: '#005286',
                    color: '#fff',
                    fontSize: '0.73rem',
                    top: '0.35rem',
                    marginY: '0.5rem',
                    marginX: '0.2rem',
                }}
                >
                    United we stand,
                    prioritized they fall
                </Chip>
                <List size="lg" variant="plain" component="nav" className='side-nav-bar-list font-light' onClick={onClick}
                    sx={{
                        "--ListDivider-gap": "6px",
                        "--ListItemDecorator-size": "32px",
                        "--ListItemDecorator-fontSize": "40px",
                        "--ListItemContent-gap": "10px",
                        "--ListSubheader-fontSize": "16px",
                        "--ListItemContent-fontWeight": "light",
                        "--ListItemButton-paddingLeft": "16px",
                        "--List-border": "none",
                    }}>
                    <ListItem sx={{
                        paddingTop: '0',
                    }}>
                    <ListItemButton component={Link} to="/">
                        <ListItemDecorator>
                            <HomeOutlinedIcon sx={{ fontSize: '24px'}}/>
                        </ListItemDecorator>
                        <ListItemContent>Home</ListItemContent>
                    </ListItemButton>
                    </ListItem>
                    <ListItem>
                    <ListItemButton disabled component={Link} to="/explore">
                        <ListItemDecorator>
                            <ExploreIcon sx={{ fontSize: '24px'}}/>
                        </ListItemDecorator>
                        <ListItemContent>Explore</ListItemContent>
                    </ListItemButton>
                    </ListItem>
                    <ListItem>
                    <ListItemButton component={Link} to="/feedback">
                        <ListItemDecorator>
                            <ReviewsOutlinedIcon sx={{ fontSize: '24px'}}/>
                        </ListItemDecorator>
                        <ListItemContent>Feedback</ListItemContent>
                    </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    {currentUser && currentUser.attributes.is_admin && (
                    <ListItem nested>
                        <ListSubheader sx={{ fontSize: '16px', paddingLeft: '4px', fontWeight: 'semi-bold' }}>Admin</ListSubheader>
                            <ListItem>
                            <ListItemButton component={Link} to="/admin">
                                <ListItemDecorator>
                                    <AdminPanelSettingsOutlinedIcon sx={{ fontSize: '24px'}}/>
                                </ListItemDecorator>
                                <ListItemContent>Admin Home</ListItemContent>
                            </ListItemButton>
                            </ListItem>                            
                            <ListItem>
                            <ListItemButton component={Link} to="/admin/reports">
                                <ListItemDecorator>
                                    <FlagCircleOutlinedIcon sx={{ fontSize: '24px'}}/>
                                </ListItemDecorator>
                                <ListItemContent>Reported Items</ListItemContent>
                            </ListItemButton>
                            </ListItem>
                            <ListItem>
                            <ListItemButton component={Link} to="/admin/feedback">
                                <ListItemDecorator>
                                    <BugReportOutlinedIcon sx={{ fontSize: '24px'}}/>
                                </ListItemDecorator>
                                <ListItemContent>User Feedback</ListItemContent>
                            </ListItemButton>
                            </ListItem>
                    </ListItem>
                    )}
                </List>

                {/* Footer */}
                <Typography
                component="span"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    px: 2,
                    py: 2,
                    fontSize: '10px', // Override text size here
                    fontWeight: 'light',
                    color: 'text.secondary',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                }}
                >
                <a
                    className="societea-footer"
                    href="https://societea.io"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    Societea, Inc. © 2024. All rights reserved.
                </a>
                </Typography>
             </CustomDrawer>
        </div>
    )
}

export default SideNavBar