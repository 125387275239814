import React, { useState, useEffect } from 'react'
import api from 'config/axiosConfig'
import { useUser } from 'util/UserContext'
import FeedbackCard from './FeedbackCard'
import AddFeedback from './AddFeedback'
import CircularProgress from '@mui/material/CircularProgress'

import { Select, Option, Box, Typography, Button } from '@mui/joy'
import {
    Feedback,
    Entity,
    Sort,
    SortField,
    SortOrder
} from 'util/constants'

interface Props {
    sort: Sort | undefined
}

const FeedbackList = ({ sort }: Props) => {
    const { currentUser, setShowAuthModal } = useUser()
    const sortOptions = [
        { label: 'Top', value: 'top', link: '/feedback/top/' },
        { label: 'New', value: 'new', link: '/feedback/new/' },
        { label: 'Bottom', value: 'bottom', link: '/feedback/bottom/' },
        { label: 'Old', value: 'old', link: '/feedback/old/' },
    ]

    const [feedback, setFeedback] = useState<Feedback[]>([])
    const [error, setError] = useState('')
    const [filterCategory, setFilterCategory] = useState<Feedback['properties']['category']>('All')
    const [filterStatus, setFilterStatus] = useState<Feedback['status']>('All')
    const [sortField, setSortField] = useState<SortField>()
    const [sortOrder, setSortOrder] = useState<SortOrder>()
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isAddFeedbackOpen, setIsAddFeedbackOpen] = useState(false)

    useEffect(() => {
        switch (sort) {
            case 'top':
                setSortField('vote_count')
                setSortOrder('desc')
                break
            case 'bottom':
                setSortField('vote_count')
                setSortOrder('asc')
                break
            case 'new':
                setSortField('created_ts')
                setSortOrder('desc')
                break
            case 'old':
                setSortField('created_ts')
                setSortOrder('asc')
                break
            default:
                setSortField('vote_count')
                setSortOrder('desc')
                break
        }
    }, [sort])

    useEffect(() => {
        if (sortField && sortOrder) {
            setLoading(true)
            setFeedback([])
            setPage(1)
            fetchFeedback(1)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortField, sortOrder])

    const fetchFeedback = async (page: number) => {
        try {
            const response = await api.get('/api/entities', {
                params: {
                    entityType: 'feedback',
                    sortField: sortField,
                    sortOrder: sortOrder,
                    limit: 10,
                    offset: (page - 1) * 10,
                },
            })

            if (response.data.length < 10) {
                setHasMore(false)
            }

            setFeedback((prevFeedback: Feedback[]) => {
                const newFeedback = response.data.map(
                    (feedback: Entity) => feedback.attributes
                )
                const uniqueFeedback = newFeedback.filter(
                    (newFeedback: Feedback) =>
                        !prevFeedback.some((feedback) => feedback.id === newFeedback.id)
                )
                return [...prevFeedback, ...uniqueFeedback]
            })
            setLoading(false)
        } catch (error) {
            setError('Failed to fetch feedback')
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchFeedback(page)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, loading])

    const handleScroll = () => {
        if (loading || !hasMore) return

        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 200
        ) {
            setPage((prevPage) => prevPage + 1)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, hasMore])

    const handleCategoryChange = (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
        newValue: Feedback['properties']['category']
    ) => setFilterCategory(newValue)

    const handleStatusChange = (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
        newValue: Feedback['status'] | null
    ) => setFilterStatus(newValue || '')

    const handleSortChange = (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
        newValue: Sort | null
    ) => {
        const selectedOption = sortOptions.find(
            (option) => option.value === newValue
        )
        if (selectedOption) {
            window.location.href = selectedOption.link
        }
    }

    const filteredFeedback = feedback.filter((item) => {
        return (
            (filterCategory === 'All' || item.properties?.category === filterCategory) &&
            (filterStatus === 'All' || item.properties?.status === filterStatus)
        )
    })

    if (loading && page === 1) {
        return <CircularProgress />
    }

    if (error) {
        return <div>{error}</div>
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    py: 1,
                    px: 2,
                    borderBottom: '0.05rem solid #c8c8c8',
                }}
            >
                <Select
                    size="sm"
                    value={sort || 'top'}
                    onChange={handleSortChange}
                >
                    {sortOptions.map((sortButton) => (
                        <Option key={sortButton.value} value={sortButton.value}>
                            <Box sx={{ display: 'flex', alignItems: 'left' }}>
                                <Typography textAlign="left" sx={{ ml: 1 }}>
                                    {sortButton.label}
                                </Typography>
                            </Box>
                        </Option>
                    ))}
                </Select>

                <Select
                    size="sm"
                    value={filterCategory}
                    onChange={handleCategoryChange}
                >
                    <Option value="All">Type</Option>
                    <Option value="Bug">Bug</Option>
                    <Option value="Enhancement">Enhancement</Option>
                    <Option value="Functionality">Functionality</Option>
                    <Option value="User Interface (UI)">User Interface (UI)</Option>
                    <Option value="User Experience (UX)">User Experience (UX)</Option>
                    <Option value="Performance">Performance</Option>
                    <Option value="Compliance">Compliance</Option>
                    <Option value="Other">Other</Option>
                </Select>

                <Select
                    size="sm"
                    value={filterStatus}
                    onChange={handleStatusChange}
                >
                    <Option value="All">Status</Option>
                    <Option value="Open">Open</Option>
                    <Option value="Reviewing">Reviewing</Option>
                    <Option value="Planned">Planned</Option>
                    <Option value="Unplanned">Unplanned</Option>
                    <Option value="Developing">Developing</Option>
                    <Option value="Released">Released</Option>
                    <Option value="Closed">Closed</Option>
                </Select>

                <Button
                    onClick={() => currentUser ? setIsAddFeedbackOpen(true) : setShowAuthModal(true)}
                    sx={{ marginLeft: 'auto' }}
                >
                    Add Feedback
                </Button>
            </Box>
            <div className="flex flex-col gap-2 divide-y divide-gray-200">
                {filteredFeedback.map((item) => (
                    <FeedbackCard
                        key={item.id}
                        feedback={item}
                        showMeta={true}
                        showActions={true}
                        onVote={() => {}}
                        truncateDescription={true}
                    />
                ))}
            </div>
            <AddFeedback
                open={isAddFeedbackOpen}
                onClose={() => setIsAddFeedbackOpen(false)}
                onFeedbackAdded={(newFeedback) => setFeedback([newFeedback, ...feedback])}
            />
        </div>
    )
}

export default FeedbackList