import { useNavigate } from 'react-router-dom'
import EntitiesSort from './EntitiesSort'
import { Select, Option, Box } from '@mui/joy'
import { Sort, ImpactType, Scope } from 'util/constants'

interface Props {
    sort: Sort
    searchParams: URLSearchParams
}

const ImpactsListSelector = ({ sort, searchParams }: Props) => {
    const navigate = useNavigate()
    const typeFilter = searchParams.get('typeFilter') || 'All'
    const scopeFilter = searchParams.get('scopeFilter') || 'All'

    const handleSortChange = (newSort: Sort) => {
        const newSearchParams = new URLSearchParams(searchParams)
        navigate(`/impacts/${newSort}?${newSearchParams.toString()}`)
    }

    const handleTypeChange = (newType: ImpactType) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('typeFilter', newType ?? 'Type')
        navigate(`/impacts/${sort}?${newSearchParams.toString()}`)
    }

    const handleScopeChange = (newScope: Scope) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('scopeFilter', newScope ?? 'All')
        navigate(`/impacts/${sort}?${newSearchParams.toString()}`)
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', py: .5, px: 2 }}>
            <EntitiesSort entityType="impacts" sort={sort} onSortChange={handleSortChange} />
            <Select size="sm" value={typeFilter} onChange={(_, newValue) => handleTypeChange(newValue as ImpactType)}>
                <Option value="All">Type</Option>
                <Option value="impact_negative">Negative</Option>
                <Option value="impact_positive">Positive</Option>
            </Select>
            <Select size="sm" value={scopeFilter} onChange={(_, newValue) => handleScopeChange(newValue as Scope)}>
                <Option value="All">Scope</Option>
                <Option value="Local">Local</Option>
                <Option value="National">National</Option>
                <Option value="Global">Global</Option>
            </Select>
        </Box>
    )
}

export default ImpactsListSelector