import { useNavigate } from 'react-router-dom'
import EntitiesSort from './EntitiesSort'
import { Select, Option, Box } from '@mui/joy'
import { Sort, EnemyType, Status } from 'util/constants'

interface Props {
    sort: Sort
    searchParams: URLSearchParams
}

const EnemiesListSelector = ({ sort, searchParams }: Props) => {
    const navigate = useNavigate()
    const typeFilter = searchParams.get('typeFilter') || 'All'
    const statusFilter = searchParams.get('statusFilter') || 'All'

    const handleSortChange = (newSort: Sort) => {
        const newSearchParams = new URLSearchParams(searchParams)
        navigate(`/enemies/${newSort}?${newSearchParams.toString()}`)
    }

    const handleTypeChange = (newType: EnemyType) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('typeFilter', newType ?? 'Type')
        navigate(`/enemies/${sort}?${newSearchParams.toString()}`)
    }

    const handleStatusChange = (newStatus: Status) => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('statusFilter', newStatus ?? 'All')
        navigate(`/enemies/${sort}?${newSearchParams.toString()}`)
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', py: .5, px: 2 }}>
            <EntitiesSort entityType="enemies" sort={sort} onSortChange={handleSortChange} />
            <Select size="sm" value={typeFilter} onChange={(_, newValue) => handleTypeChange(newValue as EnemyType)}>
                <Option value="All">Type</Option>
                <Option value="Person">Person</Option>
                <Option value="Corporation">Corporation</Option>
                <Option value="Organization">Organization</Option>
                <Option value="Regulation">Regulation</Option>
                <Option value="Concept">Concept</Option>
                <Option value="Environment">Environment</Option>
                <Option value="Territory">Territory</Option>
                <Option value="Other">Other</Option>
            </Select>
            <Select size="sm" value={statusFilter} onChange={(_, newValue) => handleStatusChange(newValue as Status)}>
                <Option value="All">Status</Option>
                <Option value="Active">Active</Option>
                <Option value="Eliminated">Eliminated</Option>
            </Select>
        </Box>
    )
}

export default EnemiesListSelector